import { Avatar, Box, Typography } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import API from "../../utils/api"
import NoAuthSearchListItem from "../../components/NoAuthSearchListItem";
import SearchListItemMobile from "../../components/SearchListItemMobile";
import SearchListItem from "../../components/SearchListItem";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import LinearProgress from "@mui/material/LinearProgress";
import { ThemeContext } from "../../App";
import IosShareIcon from '@mui/icons-material/IosShare';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';



function generateColorFromInitial(initial) {
    // Define an array of possible colors
    const colors = ['#ff5733', '#33ff57', '#5733ff', '#ff33d1', '#33a5ff', '#ffc933'];

    // Use the ASCII value of the initial character to pick a color from the array
    const charCode = initial.charCodeAt(0);
    const colorIndex = charCode % colors.length;

    // Return the selected color
    return colors[colorIndex];
}

export default function DashboardComponent({ userId, userData }) {
    const [catalogueData, setCatalogueData] = useState(null);
    const [loading, setLoading] = useState(true);
    const context = useContext(ThemeContext)
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up("md"));
    const auth = localStorage.getItem("jwt");

    useEffect(() => {
        console.log("userdata from dashboard:", userData)
        API.get(`userCatalogue/${userId}`).then((res) => {
            console.log("response form dashboard", res.data);
            console.log("first name", res.data[0].first_name)
            setCatalogueData(res.data);
        }).then(() => {
            setLoading(false);
        }).catch(err => {
            console.log("error from dashboard", err)
        })
    }, [])
    return (
        <div style={{ height: '100%', backgroundColor: context.appTheme === 'dark' ? '#1b1f20' : '#effafa' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center', margin: '20px', marginTop: '0px', paddingTop: '20px', marginBottom: '10px' }}>
                <Avatar sx={{ width: 100, height: 100, fontSize: '40px', backgroundColor: generateColorFromInitial(userData[0].first_name[0]) }}>{userData[0].first_name[0]}{userData[0].last_name[0]}</Avatar>
            </Box>
            {/* <Box sx={{ textAlign: 'center' }}>
                <IconButton>
                    <IosShareIcon style={{ color: context.appTheme === 'dark' ? 'white' : 'black' }} />
                </IconButton>
            </Box> */}

            <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h5" style={{ color: context.appTheme === 'dark' ? 'white' : 'black' }}>
                    {userData[0].first_name} {userData[0].last_name}
                </Typography>
                <Typography variant="h6" style={{ color: context.appTheme === 'dark' ? 'white' : 'black' }}>
                    @{userData[0].login_id}
                </Typography>
            </Box>
            <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h6" style={{ color: context.appTheme === 'dark' ? 'white' : 'black' }}>
                    {userData[0].email}
                </Typography>
            </Box>
            <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h6" style={{ color: context.appTheme === 'dark' ? 'white' : 'black' }}>
                    {loading ? <div>loading...</div> : <strong>{catalogueData.length}</strong>} Posts
                </Typography>
            </Box>
            <Box sx={{ textAlign: 'left' }}>
                {loading ? (
                    <LinearProgress color="primary" />
                ) : catalogueData && catalogueData.length > 0 ? (
                    catalogueData.map((result) => {
                        return auth ? (
                            desktop ? (
                                <SearchListItem searchResult={result} />
                            ) : (
                                <SearchListItemMobile searchResult={result} />
                            )
                        ) : desktop ? (
                            <NoAuthSearchListItem searchResult={result} />
                        ) : (
                            <SearchListItemMobile searchResult={result} />
                        );
                    })
                ) : (
                    <div style={{ textAlign: "center", color: context.appTheme === 'dark' ? 'white' : 'black' }}>
                        You are not tutoring any subjects
                    </div>
                )}
            </Box>



        </div>
    )
}

